.formCard {
  padding: 0px 0px 0px 0px;
}

.legend {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.fieldsHeader {
  color: #201f1e;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.rows {
  display: flex;
  gap: 5px;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}

.row {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-radius: 3px;
  border: 1px solid #edebe9;
}

.dialog {
  :global {
    .k-dialog-titlebar {
      padding: 20px 20px 0px 20px;

      .k-dialog-title {
        padding-bottom: 0;
      }
    }

    .k-dialog-content {
      padding: 20px;
      min-width: 422px;
    }

    .k-actions {
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
      margin-top: 30px;
    }
  }
}
