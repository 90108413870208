.icon {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;

  background-image: url(./offstreetOccupancyMore50.svg);
  top: 15px;
  width: 36px;
  height: 36px;
}
