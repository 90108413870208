.app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main {
    display: flex;
    flex: 1;
    height: calc(100% - 62px);
    z-index: 0;
}