.formCard {
  padding: 0px 0px 0px 0px;
}

.dialog {
  :global {
    .k-dialog-titlebar {
      padding: 20px 20px 0px 20px;

      .k-dialog-title {
        padding-bottom: 0;
      }
    }

    .k-dialog-content {
      padding: 20px;
      min-width: 622px;
    }

    .k-actions {
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
    }
  }
}
