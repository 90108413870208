[draggable] {
  cursor: grab;
}

.hiddenTip {
  z-index: 5;

  :global(.mapboxgl-popup-tip) {
    display: none;
  }
}
