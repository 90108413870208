.container {
  position: relative;

  .clear {
    position: absolute;
    top: 6px;
    right: 8px;
    padding: 0;
    border: none;
    color: rgb(118, 118, 118);

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

:global {
  :local(.textBox) {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    box-shadow: none;

    &:focus {
      border: none;
      box-shadow: none;
    }

    &::before {
      position: absolute;
      top: 6px;
      left: 8px;
      font-size: 16px;
      content: '\e13e';
      font-family: WebComponentsIcons !important;
      line-height: 1;
    }

    :focus,
    .k-focus,
    :focus-within &::after {
      border: none;
    }
  }
}
