.k-button-link,
.k-button {
  &:hover,
  &:focus {
    text-decoration: none;

    &:after {
      outline: none;
    }
  }
}
