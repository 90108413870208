.assets-page {
  display: flex;
  background-color: #fff;
  overflow: hidden;

  .nav-sidebar {
    background-color: #5D6C79;
    width: 200px;
    min-width: 200px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-right-width: 1px;

    .collapse-nav-icon {
      position: absolute;
      top: 20px;
      left: 185px;
      height: 27px;
      width: 27px;
      cursor: pointer;
      display: inline-block;
      background: url(./icons/hide-button.svg) no-repeat;
      z-index: 1000;
      border: none;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    &.collapsed {
      width: 50px !important;
      min-width: 50px !important;

      .collapse-nav-icon {
        left: 40px;
        height: 26px;
        width: 26px;
        background: url(./icons/show-button.svg) no-repeat;
      }

      .k-panelbar-item-text {
        visibility: hidden !important;
      }
    }

    .k-panelbar-group .k-panelbar-item,
    .k-panelbar-header {
      .k-link {
        color: white;
        background-color: #5D6C79;
        font-family: Segoe UI;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0px;
        vertical-align: middle;
        padding-left: 42px;

        .k-panelbar-item-icon {
          margin-left: 10px;
          margin-right: 10px;
        }

        &.k-selected {
          background-color: #485765;
        }

        &:hover {
          background-color: #485765;
        }
      }
    }
  }

  .content {
    padding-left: 20px;
    padding-top: 20px;
    width: calc(100% - 200px);
    overflow-y: auto;

    &.nav-collapsed {
      width: calc(100% - 50px) !important;
    }
  }
}
